import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import './assets/stylesheet/global.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRouter from './routes';
import store from './store';
import * as serviceWorker from './serviceWorker';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as EVENT from './utils/eventKeys'
import { storage as LocalStorage } from "services/config/storage";
import { Analytics } from './services/analytics'
import { version } from '../package.json'
import { v4 as uuidv4 } from 'uuid';
const uuid = uuidv4()

function mainRender() {

    // if(LocalStorage.fetch.forceLogout() == undefined){
    //     LocalStorage.set.forceLogout(false)
    // }

    let payload = {}
    payload[EVENT.PLATFORM] = 'Web'
    payload[EVENT.SESSION_ID] = LocalStorage.fetch.sessionData()?.session_data ? LocalStorage.fetch.sessionData()?.session_data : uuid
    payload[EVENT.TIMESTAMP] = new Date();
    payload[EVENT.APP_VERSION] = version
    payload[EVENT.DEVICE_ID] = ''
    payload[EVENT.CHILD_ID] = LocalStorage.fetch.defaultChild()?.id
    payload[EVENT.CHILD_LEVEL] = LocalStorage.fetch.defaultChild()?.grade_level
    Analytics.logEvents(EVENT.APP_LAUNCHED, payload);
    // if(LocalStorage.fetch.forceLogout() == false){
    //     setTimeout(() => {
    //         LocalStorage.destroy.all();
    //         store.dispatch({ type: 'RESET_STATE' })
    //         LocalStorage.set.forceLogout(true)
    //         console.log(`logged out forcefully`)
    //         window.location.assign(`${window.location.origin}/onboarding`)
    //     }, 1000);
    // }

    window.addEventListener("beforeunload", () => {
      window.parent.postMessage("iframe-closed", "*");
    });
    
    ReactDOM.render(
        <Provider store={store}>
            <AppRouter />
            <ToastContainer autoClose={2300} bodyClassName={'toast-body'} />

        </Provider>,
        document.getElementById('root'))
};

// Adding the rended to setTimeout with zero millisec to tackle FOUC
if (process.env.NODE_ENV !== "production") {
    LocalStorage.set.sessionData(uuid);
    // Workaround for https://github.com/facebook/create-react-app/issues/6399
    // until it gets fixed upstream
    setTimeout(() => {
        mainRender();
    }, 0);
} else {
    LocalStorage.set.sessionData(uuid);
    mainRender();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
